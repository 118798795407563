// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Bed/BedView.css */
.bed-info {
    margin-top: 10px;
}

.bed-info p {
    margin: 5px 0;
}

.bed-info a {
    color: #007bff;
    text-decoration: none;
}

.bed-info a:hover {
    text-decoration: underline;
}

.content .resident-info {
    margin-top: 10px;
    display: flex;
    flex-direction: column; /* Ensure resident info is displayed in a column */
    align-items: flex-start; /* Align resident info to the left */
}

.resident-info p {
    margin: 5px 0;
}

.back-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    margin-top: 10px;
}

.back-button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/Bed/BedView.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB,EAAE,kDAAkD;IAC1E,uBAAuB,EAAE,oCAAoC;AACjE;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* src/components/Bed/BedView.css */\n.bed-info {\n    margin-top: 10px;\n}\n\n.bed-info p {\n    margin: 5px 0;\n}\n\n.bed-info a {\n    color: #007bff;\n    text-decoration: none;\n}\n\n.bed-info a:hover {\n    text-decoration: underline;\n}\n\n.content .resident-info {\n    margin-top: 10px;\n    display: flex;\n    flex-direction: column; /* Ensure resident info is displayed in a column */\n    align-items: flex-start; /* Align resident info to the left */\n}\n\n.resident-info p {\n    margin: 5px 0;\n}\n\n.back-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n    border-radius: 3px;\n    margin-top: 10px;\n}\n\n.back-button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
