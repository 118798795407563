import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import './RoomView.css';

const RoomView = () => {
    const { id } = useParams();
    const [room, setRoom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [rate, setRate] = useState(null);

    useEffect(() => {
        const fetchRoom = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/api/rooms/${id}`, {
                    headers: {
                        'Accept': 'application/ld+json'
                    }
                });
                setRoom(response.data);
                setRate(response.data.rate);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRoom();
    }, [id]);

    const handleDeleteRate = async (rateId) => {
        try {
            await axios.delete(`/api/rates/${rateId}`, {
                headers: {
                    'Accept': 'application/ld+json'
                }
            });
            setRate(null);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleCreateBed = async () => {
        if (!room) return;

        const nextBedNumber = room.beds.length + 1;
        try {
            const response = await axios.post('/api/beds', {
                room: `/api/rooms/${id}`,
                name: nextBedNumber
            }, {
                headers: {
                    'Content-Type': 'application/ld+json'
                }
            });
            setRoom(prevRoom => ({
                ...prevRoom,
                beds: [...prevRoom.beds, response.data]
            }));
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDeleteBed = async (bedId) => {
        try {
            await axios.delete(`/api/beds/${bedId}`, {
                headers: {
                    'Accept': 'application/ld+json'
                }
            });
            setRoom(prevRoom => ({
                ...prevRoom,
                beds: prevRoom.beds.filter(bed => bed.id !== bedId)
            }));
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading room: {error}</p>;

    return (
        <div>
            <h3>
                <Link to={`/hostels/${room.hostel.id}`}>
                    {room.hostel.name}
                </Link> / Room {room.roomNumber}
            </h3>
            <p>{room.hostel.address}</p>

            <h4>Beds</h4>
            <ul className="bed-list">
                {room.beds.map(bed => (
                    <li key={bed.id} className="bed-list-item">
                        <Link to={`/beds/${bed.id}`} className="bed-link">
                            <div className={`bed-circle ${bed.resident ? 'occupied' : 'vacant'}`}>
                                {bed.name}
                            </div>
                        </Link>
                        {bed.resident ? (
                            <div className="resident-info">
                                <Link to={`/residents/${bed.resident.id}`} className="resident-link">
                                    {bed.resident.name} - {bed.resident.phoneNumber}
                                </Link>
                            </div>
                        ) : (
                            <div className="resident-info">Not occupied</div>
                        )}
                        <button onClick={() => handleDeleteBed(bed.id)} className="delete-bed-button">Delete</button>
                    </li>
                ))}
            </ul>

            <button onClick={handleCreateBed} className="add-bed-button">Create Bed</button>

            <h4>Rates</h4>
            {rate ? (
                <table className="rate-list-table">
                    <thead>
                    <tr>
                        <th>Daily Rate</th>
                        <th>Weekly Rate</th>
                        <th>Monthly Rate</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr key={rate.id}>
                        <td>{rate.dailyRate}</td>
                        <td>{rate.weeklyRate}</td>
                        <td>{rate.monthlyRate}</td>
                        <td>
                            <button onClick={() => handleDeleteRate(rate.id)}>Delete</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            ) : (
                <p>No rates available</p>
            )}
            {!rate && (
                <Link to={`/rooms/${id}/rates/create`}>
                    <button className="add-rate-button">Add Rate</button>
                </Link>
            )}

            <Link to={`/hostels/${room.hostel.id}`}>Back to Hostel</Link>
        </div>
    );
};

export default RoomView;
