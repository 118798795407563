// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Sidebar.css */

.sidebar {
    width: 200px;
    background-color: #f8f9fa;
    padding: 15px;
    border-right: 1px solid #ddd;
}

.sidebar h2 {
    font-size: 18px;
    margin-bottom: 15px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 10px;
}

.sidebar ul li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
}

.sidebar ul li a:hover {
    color: #007bff;
}

.sidebar ul li ul {
    list-style-type: none;
    padding: 0;
    margin-left: 15px;
}

.sidebar ul li ul li {
    margin-bottom: 5px;
}

.sidebar ul li ul li a {
    font-size: 14px;
    color: #555;
}

.sidebar ul li ul li a:hover {
    color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* src/components/Sidebar.css */\n\n.sidebar {\n    width: 200px;\n    background-color: #f8f9fa;\n    padding: 15px;\n    border-right: 1px solid #ddd;\n}\n\n.sidebar h2 {\n    font-size: 18px;\n    margin-bottom: 15px;\n}\n\n.sidebar ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.sidebar ul li {\n    margin-bottom: 10px;\n}\n\n.sidebar ul li a {\n    text-decoration: none;\n    color: #333;\n    font-size: 16px;\n}\n\n.sidebar ul li a:hover {\n    color: #007bff;\n}\n\n.sidebar ul li ul {\n    list-style-type: none;\n    padding: 0;\n    margin-left: 15px;\n}\n\n.sidebar ul li ul li {\n    margin-bottom: 5px;\n}\n\n.sidebar ul li ul li a {\n    font-size: 14px;\n    color: #555;\n}\n\n.sidebar ul li ul li a:hover {\n    color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
