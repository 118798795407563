// src/components/HostelList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './HostelList.css';

const HostelList = () => {
    const [hostels, setHostels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchHostels = async () => {
            try {
                const response = await axios.get('/api/hostels');
                setHostels(response.data['hydra:member']);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchHostels();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/hostels/${id}`);
            setHostels(hostels.filter(hostel => hostel.id !== id));
        } catch (err) {
            setError(err);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading hostels: {error.message}</p>;

    return (
        <div>
            <h2>Hostel Listing</h2>
            <Link to="/hostels/create">
                <button>Add Hostel</button>
            </Link>
            <table>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {hostels.map(hostel => (
                    <tr key={hostel.id}>
                        <td>{hostel.id}</td>
                        <td>{hostel.name}</td>
                        <td>{hostel.address}</td>
                        <td>
                            <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => navigate(`/hostels/${hostel.id}`)}>View</button>
                            <button onClick={() => navigate(`/hostels/edit/${hostel.id}`)}>Edit</button>
                            <button onClick={() => handleDelete(hostel.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default HostelList;
