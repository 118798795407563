// src/components/Resident/ResidentCreate.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './ResidentCreate.css';

const ResidentCreate = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [availableBeds, setAvailableBeds] = useState([]);
    const [selectedHostel, setSelectedHostel] = useState('');
    const [selectedRoom, setSelectedRoom] = useState('');
    const [selectedBed, setSelectedBed] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            axios.get(`/api/residents/${id}`).then(response => {
                const resident = response.data;
                setName(resident.name);
                setPhoneNumber(resident.phoneNumber);
                if (resident.bed) {
                    setSelectedHostel(resident.bed.room.hostel.id);
                    setSelectedRoom(resident.bed.room.id);
                    setSelectedBed(resident.bed.id);
                }
            });
        }

        axios.get('/api/available_beds').then(response => {
            setAvailableBeds(response.data.hostels);
        });
    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const residentData = {
            name,
            phoneNumber,
            bed: selectedBed ? `/api/beds/${selectedBed}` : null
        };

        try {
            if (id) {
                await axios.put(`/api/residents/${id}`, residentData, {
                    headers: {
                        'Content-Type': 'application/ld+json'
                    }
                });
                navigate(`/residents/${id}`);
            } else {
                const response = await axios.post('/api/residents', residentData, {
                    headers: {
                        'Content-Type': 'application/ld+json'
                    }
                });
                navigate(`/residents/${response.data.id}`);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleHostelChange = (e) => {
        setSelectedHostel(e.target.value);
        setSelectedRoom('');
        setSelectedBed('');
    };

    const handleRoomChange = (e) => {
        setSelectedRoom(e.target.value);
        setSelectedBed('');
    };

    return (
        <div className="resident-create">
            <h2>{id ? 'Edit Resident' : 'Add Resident'}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number:</label>
                    <input
                        type="text"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="hostel">Hostel:</label>
                    <select
                        id="hostel"
                        value={selectedHostel}
                        onChange={handleHostelChange}
                    >
                        <option value="">Select Hostel</option>
                        {Object.entries(availableBeds).map(([hostelId, hostel]) => (
                            <option key={hostelId} value={hostelId}>
                                {hostel.name}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedHostel && availableBeds[selectedHostel]?.rooms && (
                    <div className="form-group">
                        <label htmlFor="room">Room:</label>
                        <select
                            id="room"
                            value={selectedRoom}
                            onChange={handleRoomChange}
                        >
                            <option value="">Select Room</option>
                            {Object.entries(availableBeds[selectedHostel].rooms).map(([roomId, room]) => (
                                <option key={roomId} value={roomId}>
                                    Room {room.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {selectedRoom && availableBeds[selectedHostel]?.rooms[selectedRoom]?.beds && (
                    <div className="form-group">
                        <label htmlFor="bed">Bed:</label>
                        <select
                            id="bed"
                            value={selectedBed}
                            onChange={(e) => setSelectedBed(e.target.value)}
                        >
                            <option value="">Select Bed</option>
                            {Object.entries(availableBeds[selectedHostel].rooms[selectedRoom].beds)
                                .map(([bedId, bedName]) => (
                                    <option key={bedId} value={bedId}>
                                        Bed {bedName}
                                    </option>
                                ))}
                        </select>
                    </div>
                )}
                <button type="submit">{id ? 'Update' : 'Create'}</button>
            </form>
        </div>
    );
};

export default ResidentCreate;
