// src/components/Bed/BedView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import './BedView.css';

const BedView = () => {
    const { id } = useParams();
    const [bed, setBed] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBed = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/api/beds/${id}`, {
                    headers: {
                        'Accept': 'application/ld+json'
                    }
                });
                setBed(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBed();
    }, [id]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading bed: {error}</p>;
    if (!bed) return <p>No bed found</p>;

    return (
        <div>
            <h3>
                <Link to={`/hostels/${bed.room.hostel.id}`}>
                    {bed.room.hostel.name}
                </Link> /
                <Link to={`/rooms/${bed.room.id}`}>
                    Room {bed.room.roomNumber}
                </Link> /
                Bed {bed.name}
            </h3>
            {bed.resident ? (
                <div className="resident-info">
                    <p>Resident:</p>
                    <p>Name: <Link to={`/residents/${bed.resident.id}`}>{bed.resident.name}</Link></p>
                    <p>Telephone: <a href={`tel:${bed.resident.phoneNumber}`}>{bed.resident.phoneNumber}</a></p>
                </div>
            ) : (
                <p>No resident assigned to this bed</p>
            )}
            <div>
                <Link to="/rooms">
                    <button className="back-button">Back to Rooms</button>
                </Link>
            </div>
        </div>
    );
};

export default BedView;
