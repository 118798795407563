// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import HostelList from './components/Hostel/HostelList';
import HostelCreate from './components/Hostel/HostelCreate';
import HostelView from './components/Hostel/HostelView';
import RoomCreate from './components/Room/RoomCreate';
import RoomView from './components/Room/RoomView';
import BedView from './components/Bed/BedView';
import ResidentList from './components/Resident/ResidentList';
import ResidentCreate from './components/Resident/ResidentCreate';
import ResidentMove from './components/Resident/ResidentMove';
import ResidentView from './components/Resident/ResidentView';
import RateCreate from './components/Rate/RateCreate';
import PaymentCreate from './components/Payment/PaymentCreate';
import Chessboard from './components/Chessboard/Chessboard';
import './App.css';

function App() {
    return (
        <Router>
            <div className="container">
                <Sidebar />
                <div className="content">
                    <Routes>
                        <Route path="/hostels" element={<HostelList />} />
                        <Route path="/hostels/create" element={<HostelCreate />} />
                        <Route path="/hostels/edit/:id" element={<HostelCreate />} />
                        <Route path="/hostels/:id" element={<HostelView />} />
                        <Route path="/hostels/:id/rooms/create" element={<RoomCreate />} />
                        <Route path="/rooms/:id" element={<RoomView />} />
                        <Route path="/rooms/:id/rates/create" element={<RateCreate />} />
                        <Route path="/beds/:id" element={<BedView />} />
                        <Route path="/residents" element={<ResidentList />} />
                        <Route path="/residents/create" element={<ResidentCreate />} />
                        <Route path="/residents/:id/move" element={<ResidentMove />} />
                        <Route path="/residents/edit/:id" element={<ResidentCreate />} />
                        <Route path="/residents/:id" element={<ResidentView />} />
                        <Route path="/residents/:id/payments/create" element={<PaymentCreate />} />
                        <Route path="/chessboard/:hostelId" element={<Chessboard />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
