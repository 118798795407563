import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './ResidentMove.css';

const ResidentMove = () => {
    const { id } = useParams();
    const [resident, setResident] = useState(null);
    const [availableBeds, setAvailableBeds] = useState([]);
    const [selectedHostel, setSelectedHostel] = useState('');
    const [selectedRoom, setSelectedRoom] = useState('');
    const [selectedBed, setSelectedBed] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            axios.get(`/api/residents/${id}`).then(response => {
                const resident = response.data;
                setResident(resident);
                if (resident.bed) {
                    setSelectedHostel(resident.bed.room.hostel.id);
                    setSelectedRoom(resident.bed.room.roomNumber);
                    setSelectedBed(resident.bed.id);
                }
            });
        }

        axios.get('/api/available_beds').then(response => {
            setAvailableBeds(response.data.hostels);
        });
    }, [id]);

    const handleHostelChange = (e) => {
        setSelectedHostel(e.target.value);
        setSelectedRoom('');
        setSelectedBed('');
    };

    const handleRoomChange = (e) => {
        setSelectedRoom(e.target.value);
        setSelectedBed('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const residentData = {
            bed: selectedBed ? `/api/beds/${selectedBed}` : null
        };

        try {
            await axios.patch(`/api/residents/${id}/move`, residentData, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            });
            navigate(`/residents/${id}`);
        } catch (err) {
            console.error(err);
        }
    };

    const getRoomsForSelectedHostel = () => {
        const hostel = availableBeds[selectedHostel];
        return hostel ? Object.entries(hostel.rooms) : [];
    };

    const getBedsForSelectedRoom = () => {
        const rooms = getRoomsForSelectedHostel();
        const room = rooms.find(([roomId, room]) => room.name == selectedRoom);
        return room ? Object.entries(room[1].beds) : [];
    };

    return (
        <div className="resident-move">
            <h2>Move To Another Room</h2>
            {resident && (
                <div className="current-location">
                    <label>Current Location:</label>
                    <input
                        type="text"
                        value={resident.bed ? `Room ${resident.bed.room.roomNumber} - Bed ${resident.bed.name}` : 'Not assigned'}
                        readOnly
                    />
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="hostel">Hostel:</label>
                    <select
                        id="hostel"
                        value={selectedHostel}
                        onChange={handleHostelChange}
                    >
                        <option value="">Select Hostel</option>
                        {Object.entries(availableBeds).map(([hostelId, hostel]) => (
                            <option key={hostelId} value={hostelId}>
                                {hostel.name}
                            </option>
                        ))}
                    </select>
                </div>
                {selectedHostel && (
                    <div className="form-group">
                        <label htmlFor="room">Room:</label>
                        <select
                            id="room"
                            value={selectedRoom}
                            onChange={handleRoomChange}
                        >
                            <option value="">Select Room</option>
                            {getRoomsForSelectedHostel().map(([roomId, room]) => (
                                <option key={roomId} value={room.name}>
                                    Room {room.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {selectedRoom && (
                    <div className="form-group">
                        <label htmlFor="bed">Bed:</label>
                        <select
                            id="bed"
                            value={selectedBed}
                            onChange={(e) => setSelectedBed(e.target.value)}
                        >
                            <option value="">Select Bed</option>
                            {getBedsForSelectedRoom().map(([bedId, bed]) => (
                                <option key={bedId} value={bedId}>
                                    Bed {bed}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <button type="submit">Move</button>
            </form>
        </div>
    );
};

export default ResidentMove;
