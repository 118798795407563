// src/components/Resident/ResidentView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './ResidentView.css';

const ResidentView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [resident, setResident] = useState(null);
    const [payments, setPayments] = useState([]);
    const [debt, setDebt] = useState(null);
    const [debtAmount, setDebtAmount] = useState('');
    const [debtDescription, setDebtDescription] = useState('');
    const [isEditingDebt, setIsEditingDebt] = useState(false);
    const [showDebtForm, setShowDebtForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchResident = async () => {
            try {
                const residentResponse = await axios.get(`/api/residents/${id}`, {
                    headers: {
                        'Accept': 'application/ld+json'
                    }
                });
                setResident(residentResponse.data);

                const paymentsResponse = await axios.get(`/api/payments?resident.id=${id}&order[endDate]=desc`, {
                    headers: {
                        'Accept': 'application/ld+json'
                    }
                });
                setPayments(paymentsResponse.data['hydra:member']);

                const debtResponse = await axios.get(`/api/debts?resident.id=${id}`, {
                    headers: {
                        'Accept': 'application/ld+json'
                    }
                });
                if (debtResponse.data['hydra:member'].length > 0) {
                    const residentDebt = debtResponse.data['hydra:member'][0];
                    setDebt(residentDebt);
                    setDebtAmount(residentDebt.amount);
                    setDebtDescription(residentDebt.description);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchResident();
    }, [id]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const handleAddDebt = async () => {
        const debtData = {
            amount: parseFloat(debtAmount),
            description: debtDescription,
            resident: `/api/residents/${id}`
        };

        try {
            const response = await axios.post('/api/debts', debtData, {
                headers: {
                    'Content-Type': 'application/ld+json'
                }
            });
            setDebt(response.data);
            setShowDebtForm(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleUpdateDebt = async () => {
        const debtData = {
            amount: parseFloat(debtAmount),
            description: debtDescription,
            resident: `/api/residents/${id}` // Ensure resident information is included
        };

        try {
            await axios.put(`/api/debts/${debt.id}`, debtData, {
                headers: {
                    'Content-Type': 'application/ld+json'
                }
            });
            setDebtAmount(debtData.amount);
            setDebtDescription(debtData.description);
            setIsEditingDebt(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleDeleteDebt = async () => {
        try {
            await axios.delete(`/api/debts/${debt.id}`, {
                headers: {
                    'Accept': 'application/ld+json'
                }
            });
            setDebt(null);
            setDebtAmount('');
            setDebtDescription('');
        } catch (err) {
            console.error(err);
        }
    };

    const handleMove = () => {
        if (window.confirm('Are you sure you want to move this resident to another room?')) {
            navigate(`/residents/${id}/move`);
        }
    };

    const handleCheckOut = async () => {
        if (window.confirm('Are you sure you want to check out this resident?')) {
            try {
                await axios.patch(`/api/residents/${id}/checkout`, {}, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                });
                navigate('/residents');
            } catch (err) {
                console.error(err);
            }
        }
    };

    const handleAssign = () => {
        navigate(`/residents/edit/${id}`);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading resident: {error}</p>;

    return (
        <div>
            <h3>Resident Details</h3>
            {resident && (
                <div className="resident-details">
                    <p><strong>Name:</strong> {resident.name}</p>
                    <p><strong>Phone Number:</strong> {resident.phoneNumber}</p>
                    {resident.bed && (
                        <>
                            <p><strong>Room:</strong> <Link to={`/rooms/${resident.bed.room.id}`}>Room {resident.bed.room.roomNumber}</Link></p>
                            <p><strong>Bed:</strong> <Link to={`/beds/${resident.bed.id}`}>Bed {resident.bed.name}</Link></p>
                        </>
                    )}
                </div>
            )}
            <h3>Payments</h3>
            {resident && resident.bed && (
                <Link to={`/residents/${id}/payments/create`}>
                    <button className="add-payment-button">Add Payment</button>
                </Link>
            )}
            {payments.length > 0 ? (
                <table className="payments-table">
                    <thead>
                    <tr>
                        <th>Amount</th>
                        <th>Period</th>
                        <th>Payment Date</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map(payment => (
                        <tr key={payment.id} style={{ backgroundColor: payment.paymentDate ? '' : 'lightyellow' }}>
                            <td>{payment.amount}</td>
                            <td>{payment.period}</td>
                            <td>{payment.paymentDate ? formatDate(payment.paymentDate) : 'Awaiting Payment'}</td>
                            <td>{formatDate(payment.startDate)}</td>
                            <td>{formatDate(payment.endDate)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ) : (
                <p>No payments available</p>
            )}
            <h3>Debt</h3>
            {debt ? (
                <div className="debt-section">
                    {isEditingDebt ? (
                        <div className="debt-form">
                            <div className="form-group">
                                <label>Amount:</label>
                                <input
                                    type="number"
                                    value={debtAmount}
                                    onChange={(e) => setDebtAmount(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Description:</label>
                                <input
                                    type="text"
                                    value={debtDescription}
                                    onChange={(e) => setDebtDescription(e.target.value)}
                                />
                            </div>
                            <button onClick={handleUpdateDebt}>Save</button>
                            <button onClick={() => setIsEditingDebt(false)}>Cancel</button>
                        </div>
                    ) : (
                        <div className="debt-details">
                            <p><strong>Amount:</strong> {debt.amount}</p>
                            <p><strong>Description:</strong> {debt.description}</p>
                            <button onClick={() => setIsEditingDebt(true)}>Edit</button>
                            <button onClick={handleDeleteDebt}>Delete</button>
                        </div>
                    )}
                </div>
            ) : (
                !showDebtForm && (
                    <button onClick={() => setShowDebtForm(true)}>Add Debt</button>
                )
            )}
            {showDebtForm && (
                <div className="debt-form">
                    <div className="form-group">
                        <label>Amount:</label>
                        <input
                            type="number"
                            value={debtAmount}
                            onChange={(e) => setDebtAmount(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Description:</label>
                        <input
                            type="text"
                            value={debtDescription}
                            onChange={(e) => setDebtDescription(e.target.value)}
                        />
                    </div>
                    <button onClick={handleAddDebt}>Add Debt</button>
                </div>
            )}
            <div className="resident-actions">
                {resident && resident.bed ? (
                    <>
                        <button onClick={handleMove}>Move to Another Room</button>
                        <button onClick={handleCheckOut}>Check Out</button>
                    </>
                ) : (
                    <button onClick={handleAssign}>Assign</button>
                )}
            </div>
        </div>
    );
};

export default ResidentView;
