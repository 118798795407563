// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Rate/RateCreate.css */
.rate-form {
    display: flex;
    flex-direction: column;
}

.rate-form label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.rate-form input[type="number"] {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.submit-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
    margin-top: 10px;
}

.submit-button:hover {
    background-color: #218838;
}

.error {
    color: red;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Rate/RateCreate.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB","sourcesContent":["/* src/components/Rate/RateCreate.css */\n.rate-form {\n    display: flex;\n    flex-direction: column;\n}\n\n.rate-form label {\n    display: flex;\n    align-items: center;\n    margin-bottom: 10px;\n}\n\n.rate-form input[type=\"number\"] {\n    margin-left: 10px;\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n}\n\n.submit-button {\n    background-color: #28a745;\n    color: white;\n    border: none;\n    padding: 10px;\n    cursor: pointer;\n    border-radius: 3px;\n    margin-top: 10px;\n}\n\n.submit-button:hover {\n    background-color: #218838;\n}\n\n.error {\n    color: red;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
