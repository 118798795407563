// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Chessboard/Chessboard.css */

.chessboard-container {
    margin: 20px;
}

.filter-container {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.chessboard {
    width: 100%;
    border-collapse: collapse;
}

.chessboard th, .chessboard td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.chessboard th {
    background-color: #f2f2f2;
}

.chessboard .weekend {
    background-color: #ffebee;
}

.chessboard .paid {
    background-color: #d1ffd6;
}

.chessboard .faktura {
    background-color: #fffacd;
}

.chessboard .unpaid {
    background-color: #ffd6d6;
}

.chessboard-container a {
    color: #000;
    text-decoration: none;
}

.chessboard-container a:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chessboard/Chessboard.css"],"names":[],"mappings":"AAAA,6CAA6C;;AAE7C;IACI,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* src/components/Chessboard/Chessboard.css */\n\n.chessboard-container {\n    margin: 20px;\n}\n\n.filter-container {\n    margin-bottom: 10px;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n.chessboard {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.chessboard th, .chessboard td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: center;\n}\n\n.chessboard th {\n    background-color: #f2f2f2;\n}\n\n.chessboard .weekend {\n    background-color: #ffebee;\n}\n\n.chessboard .paid {\n    background-color: #d1ffd6;\n}\n\n.chessboard .faktura {\n    background-color: #fffacd;\n}\n\n.chessboard .unpaid {\n    background-color: #ffd6d6;\n}\n\n.chessboard-container a {\n    color: #000;\n    text-decoration: none;\n}\n\n.chessboard-container a:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
