// src/components/Chessboard/Chessboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Chessboard.css';

const Chessboard = () => {
    const { hostelId } = useParams();
    const [rooms, setRooms] = useState([]);
    const [payments, setPayments] = useState([]);
    const [startDate, setStartDate] = useState(new Date(2024, 5, 1)); // June 1, 2024
    const [endDate, setEndDate] = useState(new Date(2024, 5, 30)); // June 30, 2024
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(new Date());

    useEffect(() => {
        const fetchRooms = async () => {
            const response = await axios.get('/api/rooms', {
                params: {
                    'hostel.id': hostelId
                },
                headers: {
                    'Accept': 'application/ld+json'
                }
            });
            setRooms(response.data['hydra:member']);
        };

        const fetchPayments = async () => {
            const adjustedEndDate = new Date(endDate);
            adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

            const response = await axios.get('/api/payments_daily', {
                params: {
                    start_date: startDate.toISOString().split('T')[0],
                    end_date: adjustedEndDate.toISOString().split('T')[0],
                },
                headers: {
                    'Accept': 'application/ld+json'
                }
            });
            setPayments(response.data);
        };

        fetchRooms();
        fetchPayments();
    }, [startDate, endDate, hostelId]);

    const handleMonthChange = (direction) => {
        const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + direction));
        setCurrentMonth(newMonth);
        setStartDate(new Date(newMonth.getFullYear(), newMonth.getMonth(), 1));
        setEndDate(new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 0));
    };

    const handleWeekChange = (direction) => {
        const newWeek = new Date(currentWeek.setDate(currentWeek.getDate() + direction * 7));
        setCurrentWeek(newWeek);

        const startOfWeek = new Date(newWeek.setDate(newWeek.getDate() - newWeek.getDay() + 1));
        startOfWeek.setHours(0, 0, 0, 0); // Устанавливаем время на полночь

        const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);
        endOfWeek.setHours(23, 59, 59, 999); // Устанавливаем время на конец дня

        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
    };

    const renderDates = () => {
        const dates = [];
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    const formatDate = (date) => date.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit' });

    const dates = renderDates();

    const getPaymentForDate = (bedId, date) => {
        return payments.find(payment => payment.date_of_month === date.toISOString().split('T')[0] && payment.bed_id === bedId);
    };

    return (
        <div className="chessboard-container">
            <div className="filter-container">
                <button onClick={() => handleMonthChange(-1)}>-</button>
                <span>{currentMonth.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' })}</span>
                <button onClick={() => handleMonthChange(1)}>+</button>
            </div>
            <div className="filter-container">
                <button onClick={() => handleWeekChange(-1)}>-</button>
                <span>{`Week of ${currentWeek.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: 'numeric' })}`}</span>
                <button onClick={() => handleWeekChange(1)}>+</button>
            </div>
            <div className="filter-container">
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd.MM.yyyy" />
                <span>-</span>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd.MM.yyyy" />
            </div>
            <table className="chessboard">
                <thead>
                <tr>
                    <th>Bed / Date</th>
                    {dates.map(date => (
                        <th key={date} className={date.getDay() === 0 || date.getDay() === 6 ? 'weekend' : ''}>
                            {formatDate(date)}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {rooms.map(room => (
                    room.beds.map(bed => (
                        <tr key={bed.id}>
                            <td>{`${room.roomNumber}-${bed.name}`}</td>
                            {dates.map(date => {
                                const paymentForDate = getPaymentForDate(bed.id, date);
                                return (
                                    <td key={`${bed.id}-${date}`}>
                                        {paymentForDate ? (
                                            <div className={paymentForDate.faktura ? 'faktura' : 'paid'}>
                                                {typeof paymentForDate.daily_payment === 'number' ? paymentForDate.daily_payment.toFixed(2) : paymentForDate.daily_payment}<br />
                                                <a href={`/residents/${paymentForDate.resident_id}`}>{paymentForDate.resident_id}</a>
                                            </div>
                                        ) : (
                                            <div className="unpaid"></div>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    ))
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Chessboard;
