// src/components/Sidebar.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';

const Sidebar = () => {
    const [hostels, setHostels] = useState([]);

    useEffect(() => {
        const fetchHostels = async () => {
            const response = await axios.get('/api/hostels', {
                headers: {
                    'Accept': 'application/ld+json'
                }
            });
            setHostels(response.data['hydra:member']);
        };

        fetchHostels();
    }, []);

    return (
        <div className="sidebar">
            <h2>Menu</h2>
            <ul>
                <li><Link to="/hostels">Hostels</Link></li>
                <li><Link to="/residents">Residents</Link></li>
                <li>
                    Chessboard
                    <ul>
                        {hostels.map(hostel => (
                            <li key={hostel.id}>
                                <Link to={`/chessboard/${hostel.id}`}>{hostel.name}</Link>
                            </li>
                        ))}
                    </ul>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
