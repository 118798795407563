// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Resident/ResidentView.css */
.resident-details {
    margin-bottom: 20px;
}

.payments-table,
.debt-section {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
}

.payments-table th,
.debt-section th,
.payments-table td,
.debt-section td {
    border: 1px solid #ddd;
    padding: 8px;
}

.payments-table th,
.debt-section th {
    background-color: #f2f2f2;
}

.debt-form .form-group {
    margin-bottom: 10px;
}

.debt-form label {
    display: block;
    margin-bottom: 5px;
}

.debt-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
}

.debt-actions {
    margin-top: 10px;
}

.debt-actions button {
    margin-right: 10px;
    padding: 8px 16px;
}

.resident-actions {
    margin-top: 20px;
}

.resident-actions button {
    margin-right: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.resident-actions button:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/Resident/ResidentView.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;IACI,mBAAmB;AACvB;;AAEA;;IAEI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;;;;IAII,sBAAsB;IACtB,YAAY;AAChB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* src/components/Resident/ResidentView.css */\n.resident-details {\n    margin-bottom: 20px;\n}\n\n.payments-table,\n.debt-section {\n    width: 100%;\n    margin-bottom: 20px;\n    border-collapse: collapse;\n}\n\n.payments-table th,\n.debt-section th,\n.payments-table td,\n.debt-section td {\n    border: 1px solid #ddd;\n    padding: 8px;\n}\n\n.payments-table th,\n.debt-section th {\n    background-color: #f2f2f2;\n}\n\n.debt-form .form-group {\n    margin-bottom: 10px;\n}\n\n.debt-form label {\n    display: block;\n    margin-bottom: 5px;\n}\n\n.debt-form input {\n    width: 100%;\n    padding: 8px;\n    margin-bottom: 10px;\n}\n\n.debt-actions {\n    margin-top: 10px;\n}\n\n.debt-actions button {\n    margin-right: 10px;\n    padding: 8px 16px;\n}\n\n.resident-actions {\n    margin-top: 20px;\n}\n\n.resident-actions button {\n    margin-right: 10px;\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    cursor: pointer;\n    border-radius: 3px;\n}\n\n.resident-actions button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
