// src/components/Room/RoomCreate.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './RoomCreate.css';

const RoomCreate = () => {
    const { id: hostelId } = useParams();
    const [roomNumber, setRoomNumber] = useState('');
    const [bedsNumber, setBedsNumber] = useState(0);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.post('/api/rooms', {
                roomNumber: parseInt(roomNumber, 10),
                bedsNumber: parseInt(bedsNumber, 10),
                hostel: `/api/hostels/${hostelId}`
            }, {
                headers: {
                    'Content-Type': 'application/ld+json',
                }
            });
            navigate(`/hostels/${hostelId}`);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="form-container">
            <h2>Add Room</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Room Number:</label>
                    <input
                        type="text"
                        value={roomNumber}
                        onChange={(e) => setRoomNumber(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Beds Number:</label>
                    <input
                        type="number"
                        value={bedsNumber}
                        onChange={(e) => setBedsNumber(parseInt(e.target.value, 10))}
                        required
                    />
                </div>
                <button type="submit">Add Room</button>
                {error && <p className="error">Error: {error}</p>}
            </form>
        </div>
    );
};

export default RoomCreate;
